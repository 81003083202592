
  import {defineComponent, computed} from "vue"
  import jsonpointer from "jsonpointer"
  import { getForm, setElement } from "@/components/shared/form/form-provider"

  export default defineComponent({
    model: {
      prop: 'elementValue',
      event: 'update:elementValue'
    },
    props: {
      schema: {
        type: Object,
        required: true
      },
      parentScope: {
        type: String,
        required: true
      },
      title: {
        type: String
      },
      widget: {
        type: [String, Function]
      },
      required: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => ({
          disabled: false
        })
      }
    },
    setup(props, { emit }) {
      const form = getForm()

      const checkedValue = computed({
        get: () => jsonpointer.get(form.value, props.parentScope),
        set: (value) => {
          if (props.options.setter) {
            props.options.setter(setElement, props.parentScope, value)
          } else {
            setElement(props.parentScope, value)
          }
        }
      })

      // const disabled = computed(() => props.opts && props.opts.disabled)

      return {
        checkedValue,
      }
    }
  })
